import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Paragraph } from 'components/common/Styles';

import TypeFormComponent from 'components/common/TypeForm';
import { ContentArticle820 } from 'components/Content';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import Footer from 'components/Footer/Footer';

const Media = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<>
			<Seo
				title='Media Enquiries: Contact Us - BookPhysio.com'
				description='Are you looking for our press release or media kit? Describe what you need, and a member of the BookPhysio.com will get back to you with all the info you need.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<Row>
							<Column>
								<Heading>Are you looking for a press release or media kit?</Heading>
								<Paragraph>
									Please{' '}
									<HoveredInline>
										<a
											href='https://localphysionetwork.typeform.com/to/k0sNSMDP'
											target='_blank'
											rel='noopener noreferrer'
										>
											fill in this quick form
										</a>
									</HoveredInline>
									, and we'll get back to you ASAP with all the information and assets you need - we
									look forward to it!
								</Paragraph>
							</Column>
							<Column>
								<StaticImage src='../images/media-hero.jpg' alt='hero' width={350} height={230} />
							</Column>
						</Row>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};
const Heading = styled.div`
	font-weight: 600;
	margin: 40px 0 10px;
	font-style: normal;
	font-size: 18px;
	line-height: 22px;
	color: #333e48;
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	margin: 80px 0 25px;
	@media (max-width: 767px) {
		flex-direction: column;
		margin: 50px 0 0;
	}
`;
const Column = styled.div`
	flex: 0 0 50%;

	& img {
		border-radius: 6px;
	}
	&:last-child {
		padding: 0 0 0 40px;
		@media (max-width: 767px) {
			display: flex;
			justify-content: center;
			padding: 40px 0;
		}
	}
`;
const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0;
	&:first-child {
		margin: 0;
	}
`;

export default Media;
